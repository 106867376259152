import React from 'react'
import { Provider } from 'react-redux'
import stores from './redux/stores'
import Router from './components/Router/Router'
import {configMoment} from "./config/moment";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";

configMoment()

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
            staleTime: Infinity,
            retry: false,
        },
    },
});

const AppProvider = () => {
    return (
        <QueryClientProvider client={queryClient}>
            <Provider store={stores}>
                <Router />
            </Provider>
        </QueryClientProvider>
    )
}

export default AppProvider
