import {connect} from 'react-redux'
import {compose} from 'redux'
import Home from "./Home";
import {
    FETCH_TOTAL_TASKS,
    fetchTotalTasks
} from "../../../../../redux/actions/app/tasks/tasks.actions";
import {
    getSettings
} from "../../../../../redux/actions/app/settings/settings.actions";
import loader from "../../../../Commun/Loader/loader";
import {
    getLoadingEntity,
    getTotalTasks,
} from "../../../../../redux/selectors/ui/ui.selectors";
import {getTasksAnTotalLink, settingsLink} from "../../../../../redux/selectors/links/links.selectors";
import {localStorageAMO} from "../../../../../redux/selectors/localStorage/localStorage";
import { getUserPermissions, getUserRoles } from '../../../../../redux/selectors/user/user.selectors';
import {getSettingsData} from "../../../../../redux/selectors/settings/settings.selector";

const mapStateToProps = state => {
    return {
        message: localStorageAMO.home_message,
        query: getTasksAnTotalLink(state),
        totalTasks: getTotalTasks(state),
        loaded: getLoadingEntity(state, FETCH_TOTAL_TASKS) === false,
        permissions: getUserPermissions(state),
        roles: getUserRoles(state),
        querySettings: settingsLink(state),
        settings: getSettingsData(state)
    }
}

const mapDispatchToProps = {
    fetchTotalTasks,
    getSettings
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { query, querySettings } = stateProps
    const { fetchTotalTasks, getSettings } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => {
            fetchTotalTasks({query})
            getSettings({query: querySettings})
        }
    }
}

const HomeContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(Home)

export default HomeContainer
