import _ from 'lodash';
import moment from 'moment';
import { ListDocuments } from '../../../../components/Router/App/Page/WorkSpace/TypeDocument/ListDocuments';
import { APP_URL_PASS } from '../../../../config/constants';
import {
    fetchFirstTasks,
    FETCH_ADD_DOCUMENT,
    FETCH_FIRST_TASKS,
    FETCH_TOTAL_TASKS,
    postCloseTasks,
    POST_CLOSE_TASK,
    POST_REASSIGN_TASK,
    POST_REASSIGN_TASK_DOCUMENT,
    POST_RETRY_DOCUMENT,
    POST_TYPAGE_DOCUMENT,
    POST_VALIDATE_FIELDS,
    setFirstTasks,
    setNewDocument,
    setNewFormAfterTypage,
    setTotalTasks,
} from '../../../actions/app/tasks/tasks.actions';
import { setError, setIndexFile, setLoader, setMaxIndexFile } from '../../../actions/app/ui/ui.actions';
import { apiRequest, API_ERROR, API_SUCCESS } from '../../../actions/core/api/api.actions';
import { setNotification } from '../../../actions/core/notifications/notifications.actions';

export const tasksMiddleware =
    ({ dispatch }) =>
    (next) =>
    (action) => {
        next(action);
        let nextActions;

        switch (action.type) {
            /* FETCH_TOTAL_TASKS */

            case FETCH_TOTAL_TASKS:
                next([
                    apiRequest({ body: null, method: 'GET', url: action.payload.data, entity: FETCH_TOTAL_TASKS }),
                    setLoader({ state: true, entity: FETCH_TOTAL_TASKS }),
                ]);
                break;

            case `${FETCH_TOTAL_TASKS} ${API_SUCCESS}`:
                next([
                    setTotalTasks({ data: action.payload.data }),
                    setError({ state: false, entity: FETCH_TOTAL_TASKS }),
                    setLoader({ state: false, entity: FETCH_TOTAL_TASKS }),
                ]);
                break;

            case `${FETCH_TOTAL_TASKS} ${API_ERROR}`:
                next([
                    setError({ state: true, entity: FETCH_TOTAL_TASKS }),
                    setLoader({ state: false, entity: FETCH_TOTAL_TASKS }),
                ]);
                break;

            /* FETCH_FIRST_TASKS */
            case FETCH_FIRST_TASKS:
                next([
                    apiRequest({
                        body: null,
                        method: 'GET',
                        url: action.payload.data,
                        entity: FETCH_FIRST_TASKS,
                        otherData: { ...action.payload.otherData },
                    }),
                    setLoader({ state: true, entity: FETCH_FIRST_TASKS }),
                ]);
                break;

            case `${FETCH_FIRST_TASKS} ${API_SUCCESS}`:
                nextActions = [];
                if (action.payload.data.message) {
                    nextActions.push(
                        setNotification({
                            data: action.payload.data.message,
                            html: action.payload.data.message,
                            icon: 'success',
                            entity: FETCH_FIRST_TASKS,
                            title: 'Tâche traitée !',
                            confirmButtonText: "J'ai compris",
                        }),
                    );
                } else {
                    const indexNotProcessed = action.payload.data.files_url.findIndex((file) => !file.already_processed);

                    nextActions = [
                        setIndexFile({ data: indexNotProcessed, entity: FETCH_FIRST_TASKS }),
                        setMaxIndexFile({ data: action.payload.data.files_url.length - 1, entity: FETCH_FIRST_TASKS }),
                        setFirstTasks({ data: action.payload.data }),
                    ];
                }

                next([
                    ...nextActions,
                    setError({ state: false, entity: FETCH_FIRST_TASKS }),
                    setLoader({ state: false, entity: FETCH_FIRST_TASKS }),
                ]);
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
                break;

            case `${FETCH_FIRST_TASKS} ${API_ERROR}`:
                next([
                    setNotification({
                        entity: FETCH_FIRST_TASKS,
                        html: `<div><p>${
                            action.payload.data.response?.data.reason ||
                            (action.payload.meta?.otherData?.taskId
                                ? "La tâche n'est plus disponible"
                                : "Il n'y a plus de tâches a traiter")
                        }</p></div>`,
                        icon: 'error',
                        title: 'Attention !',
                        confirmButtonText: "J'ai compris",
                    }),
                    setError({ state: true, entity: FETCH_FIRST_TASKS }),
                    setLoader({ state: false, entity: FETCH_FIRST_TASKS }),
                ]);
                break;

            /* POST_TYPAGE_DOCUMENT */
            case POST_TYPAGE_DOCUMENT:
                next([
                    apiRequest({
                        body: action.payload.body,
                        method: 'POST',
                        url: action.payload.data,
                        entity: POST_TYPAGE_DOCUMENT,
                        otherData: action.payload.body.indexFile,
                    }),
                    setLoader({ state: true, entity: POST_TYPAGE_DOCUMENT }),
                ]);
                break;

            case `${POST_TYPAGE_DOCUMENT} ${API_SUCCESS}`:
                next([
                    setNewFormAfterTypage({
                        data: {
                            fields: action.payload.data.fields,
                            fiche_pratique: action.payload.data.fiche_pratique,
                        },
                        index: action.payload.meta.otherData,
                    }),
                    setError({ state: false, entity: POST_TYPAGE_DOCUMENT }),
                    setLoader({ state: false, entity: POST_TYPAGE_DOCUMENT }),
                ]);
                break;

            case `${POST_TYPAGE_DOCUMENT} ${API_ERROR}`:
                next([
                    setNewFormAfterTypage({ data: {}, index: action.payload.meta.otherData }),
                    setError({ state: true, entity: POST_TYPAGE_DOCUMENT }),
                    setLoader({ state: false, entity: POST_TYPAGE_DOCUMENT }),
                ]);
                break;

            /* POST_VALIDATE_FIELDS */

            case POST_VALIDATE_FIELDS:
                let form = {};

                Object.keys(action.payload.body.data).forEach((field) => {
                    if (_.isDate(action.payload.body.data[field].new_value)) {
                        form[`data[${field}][new_value]`] = moment(action.payload.body.data[field].new_value).format(
                            'L',
                        );
                    } else {
                        form[`data[${field}][new_value]`] = action.payload.body.data[field].new_value || '';
                    }

                    form[`data[${field}][has_minor_changes]`] = action.payload.body.data[field].has_minor_changes
                        ? '1'
                        : '0';
                    form[`data[${field}][state]`] = action.payload.body.data[field].state || '1';
                    form[`data[${field}][automation_treatment]`] = action.payload.body.data[field].automation_treatment || '0';
                });

                /* FIX IBAN BIC */
                if (!_.isEmpty(action.payload.body.iban)) {
                    Object.keys(action.payload.body.iban).forEach((field) => {
                        form[`data[${field}][new_value]`] = action.payload.body.iban[field].new_value || '';
                        form[`data[${field}][state]`] = action.payload.body.iban[field].state || '1';
                    });
                }

                form.tiers = action.payload.body.tiers;
                form.contract = action.payload.body.contract;
                form.chrono_id = action.payload.body.chrono_id;
                form.doc_type = action.payload.body.doc_type;
                form.form_type_origin = action.payload.body.form_type_origin;
                form.form_context = action.payload.body.form_context;
                form.popup_id = action.payload.body.popup_id;
                form.file_id = action.payload.body.file_id;
                form.start_timer = action.payload.body.start_timer;
                form.end_timer = Math.floor(Date.now() / 1000);
                form.is_automation_treatment = action.payload.body.is_automation_treatment;
                form.origin = action.payload.body.origin;
                form.document_type_origin = action.payload.body.document_type_origin;

                next([
                    apiRequest({
                        body: form,
                        method: 'POST',
                        url: action.payload.data,
                        entity: POST_VALIDATE_FIELDS,
                        otherData: {
                            ...action.payload.otherData,
                            context: action.payload.body.form_context,
                        },
                    }),
                    setLoader({ state: true, entity: POST_VALIDATE_FIELDS }),
                ]);
                break;

            case `${POST_VALIDATE_FIELDS} ${API_SUCCESS}`:
                const { indexFile, maxIndexFile, terminateUrl, files_url } = action.payload.meta.otherData;

                nextActions = [
                    setError({ state: false, entity: POST_VALIDATE_FIELDS }),
                    setLoader({ state: false, entity: POST_VALIDATE_FIELDS }),
                ];

                // fait une const des files restant à traiter (retire de files_url tout ce qui est avant indexFile)
                const filesToProcess = files_url.slice(indexFile + 1);
                const nextIndexFile = filesToProcess.findIndex((file) => !file.already_processed);

                if (indexFile === maxIndexFile || nextIndexFile === -1) {
                    next(nextActions);
                    dispatch(postCloseTasks({ query: terminateUrl, otherData: action.payload.meta.otherData }));
                } else {
                    // si il reste des fichiers à traiter, on passe au suivant
                    // L'index absolu dans files_url du prochain fichier non traité
                    const absoluteNextIndex = indexFile + 1 + nextIndexFile;
                    nextActions.push(setIndexFile({ data: absoluteNextIndex, entity: POST_VALIDATE_FIELDS }));
                    next(nextActions);
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth',
                    });
                }

                break;

            case `${POST_VALIDATE_FIELDS} ${API_ERROR}`:
                next([
                    setNotification({
                        entity: POST_VALIDATE_FIELDS,
                        html: `<div><p>${action.payload.data.response.data.reason}</p></div>`,
                        icon: 'error',
                        title: 'Attention !',
                        confirmButtonText: "J'ai compris",
                    }),
                    setError({ state: true, entity: POST_VALIDATE_FIELDS }),
                    setLoader({ state: false, entity: POST_VALIDATE_FIELDS }),
                ]);
                break;

            /* POST_CLOSE_TASK */

            case `${POST_CLOSE_TASK}`:
                next([
                    apiRequest({
                        body: null,
                        method: 'POST',
                        url: action.payload.data,
                        entity: POST_CLOSE_TASK,
                        otherData: action.payload.otherData,
                    }),
                    setLoader({ state: true, entity: POST_CLOSE_TASK }),
                ]);
                break;

            case `${POST_CLOSE_TASK} ${API_SUCCESS}`:
                // todo pré-chargement ajouter un setSecondTask qui ajoutera la seconde task dans le reducer task
                next([
                    setNotification({
                        entity: POST_CLOSE_TASK,
                        html: '<div><p>Tous les documents de cette tâche ont été traités.</p></div>',
                        icon: 'success',
                        title: 'Tâche terminée !',
                        confirmButtonText: action.payload.meta.otherData?.taskId
                            ? 'Terminer'
                            : 'Je continue sur la prochaine !',
                    }),
                    setError({ state: false, entity: POST_CLOSE_TASK }),
                    setLoader({ state: false, entity: POST_CLOSE_TASK }),
                ]);
                // todo pré-chargement remplacer le fetchFirstTasks par fetchSecondTasks
                //Pas de chargement de la prochaine tâche si on vient de l'EC
                if (!action.payload.meta.otherData?.taskId) {
                    dispatch(
                        fetchFirstTasks({
                            query: `${APP_URL_PASS}/tasks/first_task/${action.payload.meta.otherData.context}`,
                        }),
                    );
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth',
                    });
                } else {
                    setFirstTasks({ data: null });
                }
                break;

            case `${POST_CLOSE_TASK} ${API_ERROR}`:
                next([
                    setNotification({
                        entity: POST_CLOSE_TASK,
                        html: `<div><p>${action.payload.data.response.data.reason}</p></div>`,
                        icon: 'error',
                        title: 'Attention !',
                        confirmButtonText: "J'ai compris",
                    }),
                    setError({ state: true, entity: POST_CLOSE_TASK }),
                    setLoader({ state: false, entity: POST_CLOSE_TASK }),
                ]);
                break;

            /* POST_REASSIGN_TASK */
            case POST_REASSIGN_TASK:
                let dataForm = new FormData();

                dataForm.set('commentaire', action.payload.body.commentaire);
                dataForm.set('paniere', action.payload.body.paniere);
                dataForm.set('keep_old', action.payload.body.keep_old);
                dataForm.set('async', action.payload.body.async);
                dataForm.set('created_at', moment().format('YYYY-MM-DD 00:00:00'));

                next([
                    apiRequest({
                        body: dataForm,
                        method: 'POST',
                        url: action.payload.data,
                        entity: POST_REASSIGN_TASK,
                        otherData: action.payload.otherData,
                    }),
                    setLoader({ state: true, entity: POST_REASSIGN_TASK }),
                ]);
                break;

            case `${POST_REASSIGN_TASK} ${API_SUCCESS}`:
                next([
                    setNotification({
                        entity: POST_REASSIGN_TASK,
                        html: '<div><p>La tâche a bien été transferé au niveau 2.</p></div>',
                        icon: 'success',
                        title: 'Tâche transferée !',
                        confirmButtonText: 'Je continue sur la prochaine !',
                    }),
                    setError({ state: false, entity: POST_REASSIGN_TASK }),
                    setLoader({ state: false, entity: POST_REASSIGN_TASK }),
                ]);
                // todo pré-chargement remplacer le fetchFirstTasks par fetchSecondTasks
                dispatch(
                    fetchFirstTasks({
                        query: `${APP_URL_PASS}/tasks/first_task/${action.payload.meta.otherData.context}`,
                    }),
                );
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
                break;

            case `${POST_REASSIGN_TASK} ${API_ERROR}`:
                next([
                    setNotification({
                        entity: POST_REASSIGN_TASK,
                        html: `<div><p>${action.payload.data.response.data.reason}</p></div>`,
                        icon: 'error',
                        title: 'Attention !',
                        confirmButtonText: "J'ai compris",
                    }),
                    setError({ state: true, entity: POST_REASSIGN_TASK }),
                    setLoader({ state: false, entity: POST_REASSIGN_TASK }),
                ]);
                break;

            /* POST_REASSIGN_TASK_DOCUMENT */
            case POST_REASSIGN_TASK_DOCUMENT:
                let dataFormReassign = new FormData();

                dataFormReassign.set('commentaire', action.payload.body.commentaire);
                dataFormReassign.set('contract', action.payload.body.contract);
                dataFormReassign.set('file_id', action.payload.body.file_id);
                dataFormReassign.set('paniere', action.payload.body.paniere);

                next([
                    apiRequest({
                        body: dataFormReassign,
                        otherData: {
                            indexFile: action.payload.body.indexFile,
                            maxIndexFile: action.payload.body.maxIndexFile,
                            terminateUrl: action.payload.body.terminateUrl,
                            ...action.payload.otherData,
                        },
                        method: 'POST',
                        url: action.payload.data,
                        entity: POST_REASSIGN_TASK_DOCUMENT,
                    }),
                    setLoader({ state: true, entity: POST_REASSIGN_TASK_DOCUMENT }),
                ]);
                break;

            case `${POST_REASSIGN_TASK_DOCUMENT} ${API_SUCCESS}`:
                if (action.payload.meta.otherData.indexFile === action.payload.meta.otherData.maxIndexFile) {
                    next([
                        setError({ state: false, entity: POST_REASSIGN_TASK_DOCUMENT }),
                        setLoader({ state: false, entity: POST_REASSIGN_TASK_DOCUMENT }),
                    ]);
                    dispatch(
                        postCloseTasks({
                            query: action.payload.meta.otherData.terminateUrl,
                            otherData: action.payload.meta.otherData,
                        }),
                    );
                } else {
                    next([
                        setNotification({
                            entity: POST_REASSIGN_TASK_DOCUMENT,
                            html: '<div><p>Le document a bien été transferé au niveau 2.</p></div>',
                            icon: 'success',
                            title: 'Document transferé !',
                            confirmButtonText: 'Je continue !',
                            successFunction: action.payload.meta.otherData.successFunction,
                        }),
                        setError({ state: false, entity: POST_REASSIGN_TASK_DOCUMENT }),
                        setLoader({ state: false, entity: POST_REASSIGN_TASK_DOCUMENT }),
                        setIndexFile({
                            data: action.payload.meta.otherData.indexFile + 1,
                            entity: POST_REASSIGN_TASK_DOCUMENT,
                        }),
                    ]);
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth',
                    });
                }
                break;

            case `${POST_REASSIGN_TASK_DOCUMENT} ${API_ERROR}`:
                next([
                    setNotification({
                        entity: POST_REASSIGN_TASK_DOCUMENT,
                        html: `<div><p>${action.payload.data.response.data.reason}</p></div>`,
                        icon: 'error',
                        title: 'Attention !',
                        confirmButtonText: "J'ai compris",
                    }),
                    setError({ state: true, entity: POST_REASSIGN_TASK_DOCUMENT }),
                    setLoader({ state: false, entity: POST_REASSIGN_TASK_DOCUMENT }),
                ]);
                break;

            /* FETCH_ADD_DOCUMENT */
            case FETCH_ADD_DOCUMENT:
                next([
                    apiRequest({
                        body: action.payload.body,
                        method: 'POST',
                        url: action.payload.data,
                        entity: FETCH_ADD_DOCUMENT,
                        otherData: {
                            indexFile: action.payload.body.indexFile,
                            url: action.payload.body.file.url,
                            extension: action.payload.body.file.extension,
                            doc_type: action.payload.body.doc_type,
                            form_type_origin: action.payload.body.form_type_origin,
                            status: action.payload.body.status,
                            maxIndex: action.payload.body.maxIndex,
                            file_id: action.payload.body.file_id,
                            form_context: action.payload.body.form_context,
                        },
                    }),
                    setLoader({ state: true, entity: FETCH_ADD_DOCUMENT }),
                ]);
                break;

            case `${FETCH_ADD_DOCUMENT} ${API_SUCCESS}`:
                const document = {
                    code: action.payload.meta.otherData.form_type_origin,
                    extension: action.payload.meta.otherData.extension,
                    fields: action.payload.data.fields,
                    fiche_pratique: action.payload.data.fiche_pratique,
                    form_context: action.payload.meta.otherData.form_context,
                    libelle: _.find(ListDocuments(action.payload.meta.otherData.form_context), [
                        'code',
                        action.payload.meta.otherData.doc_type,
                    ]).libelle,
                    url: action.payload.meta.otherData.url,
                    status: action.payload.meta.otherData.status,
                    id: action.payload.meta.otherData.file_id,
                };
                next([
                    setNewDocument({ data: document, index: action.payload.meta.otherData.indexFile }),
                    setMaxIndexFile({ data: action.payload.meta.otherData.maxIndex + 1, entity: FETCH_ADD_DOCUMENT }),
                    setError({ state: false, entity: FETCH_ADD_DOCUMENT }),
                    setLoader({ state: false, entity: FETCH_ADD_DOCUMENT }),
                ]);
                break;

            case `${FETCH_ADD_DOCUMENT} ${API_ERROR}`:
                next([
                    setError({ state: true, entity: FETCH_ADD_DOCUMENT }),
                    setLoader({ state: false, entity: FETCH_ADD_DOCUMENT }),
                ]);
                break;

            /* POST_RETRY_DOCUMENT */
            case POST_RETRY_DOCUMENT:
                next([
                    apiRequest({
                        body: action.payload.body,
                        method: 'POST',
                        url: action.payload.data,
                        entity: POST_RETRY_DOCUMENT,
                    }),
                    setLoader({ state: true, entity: POST_RETRY_DOCUMENT }),
                ]);
                break;

            case `${POST_RETRY_DOCUMENT} ${API_SUCCESS}`:
                next([
                    setError({ state: false, entity: POST_RETRY_DOCUMENT }),
                    setLoader({ state: false, entity: POST_RETRY_DOCUMENT }),
                    setNotification({
                        entity: POST_RETRY_DOCUMENT,
                        html: '<div><p>Le document va être retraité par GPT.</p></div>',
                        icon: 'success',
                        title: "Document ajouté à la file d'attente !",
                        confirmButtonText: "J'ai compris",
                    }),
                ]);
                break;

            case `${POST_RETRY_DOCUMENT} ${API_ERROR}`:
                next([
                    setError({ state: true, entity: POST_RETRY_DOCUMENT }),
                    setLoader({ state: false, entity: POST_RETRY_DOCUMENT }),
                    setNotification({
                        entity: POST_RETRY_DOCUMENT,
                        html: `<div><p>${action.payload.data.response.data.reason}</p></div>`,
                        icon: 'error',
                        title: 'Attention !',
                        confirmButtonText: "J'ai compris",
                    }),
                ]);
                break;

            default:
                break;
        }
        return null;
    };
