import AllDocumentsContainer from './AllDocuments/AllDocumentsContainer';
import HomeContainer from './Home/HomeContainer';
import LogoutContainer from './Logout/LogoutContainer';
import WorkSpaceContainer from './WorkSpace/WorkSpaceContainer';
import WorkSpaceControlContainer from './WorkSpaceControl/WorkSpaceControlContainer';

const PageRoutes = [
    {
        path: '/',
        title: 'Home Page',
        permission: 'all',
        main: <HomeContainer />,
    },
    {
        path: '/ma-tache/:context',
        title: 'Traitement de la tache',
        permission: 'all',
        main: <WorkSpaceContainer origin="checkdoc" />,
    },
    {
        path: '/ma-tache/:context/:id',
        title: 'Traitement de la tache',
        permission: 'all',
        main: <WorkSpaceContainer origin="conseiller" />,
    },
    {
        path: '/ma-tache/:context/:id/mes-documents',
        title: 'Tous les documents',
        permission: 'all',
        main: <AllDocumentsContainer />,
    },
    {
        path: '/workspace-control',
        title: 'Control de documents',
        permission: 'all',
        main: <WorkSpaceControlContainer origin="control" />,
    },
    {
        path: '/logout',
        title: 'Logout',
        permission: 'all',
        main: <LogoutContainer />,
    },
];

export default PageRoutes;
