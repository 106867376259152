import React, {useState, useEffect} from 'react';
import _ from 'lodash'
import Text from "../Inputs/Text/Text";
import {checkIban} from "../../../utils/function";
import {normalizeDate, normalizeIban, normalizeYear} from "../../../utils/normalize";
import IbanField from "./IbanField";

const Iban = (props) => {
    const { correction, file, handleBlur, label, values, name, handleChange, value, disabled, setFieldValue, nameBic, nameDomiciliation } = props

    const changeData = (e) => {
        e.target.value = normalizeIban(e.target.value)

        handleChange(e)
    }

    return (
        <div>
            <div className={`${correction && "d-flex"}`}>
                <div className="w-100">
                    {
                        !correction && <Text
                            isValid={!!value && (!correction) && values.iban?.[`${file.input_name}`]?.state}
                            type={file.type}
                            name={name}
                            onChange={e => changeData(e)}
                            onBlur={handleBlur}
                            value={value?.toUpperCase() || ""}
                            required
                            placeholder={file.label}
                            label={label}
                            maxLength={file.max_length}
                            disabled={disabled}
                            additional_label={file.additional_label}
                            style={{borderRadius: '8px 0 0 8px'}}
                        />
                    }

                    {
                        correction && <IbanField
                            values={values}
                            setFieldValue={setFieldValue}
                            name={name}
                            nameBic={nameBic}
                            nameDomiciliation={nameDomiciliation}
                        />
                    }

                </div>

            </div>
        </div>
    );
};

export default Iban;
