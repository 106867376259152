import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { APP_URL_PASS } from '../../../../../config/constants';
import APILink from '../../../../Commun/APILink/APILink';
import LargeSpinner from '../../../../Commun/Spinners/LargeSpinner/LargeSpinner';
import HeaderContainer from '../../Header/HeaderContainer';
import AddDocumentContainer from './AddDocument/AddDocumentContainer';
import FieldsDocumentContainer from './FieldsDocument/FieldsDocumentContainer';
import ImgDocument from './ImgDocument/ImgDocument';
import ReassignTaskContainer from './ReassignTask/ReassignTaskContainer';
import TypeDocumentContainer from './TypeDocument/TypeDocumentContainer';

const WorkSpace = (props) => {
    const {
        initialValues,
        files_url,
        loading,
        postValidateDocument,
        indexFile,
        maxIndexFile,
        popup_id,
        contract_id,
        task,
        codeProduit,
        terminateUrl,
        formContext,
        taskId,
        postRetryDocument,
        loadingRetryDocument,
        origin,
        setIndexFile
    } = props;

    const [showAddDoc, setShowAddDoc] = useState(false);
    const [showFormReassign, setShowFormReassign] = useState(false);
    const [paniereFormReassign, setPaniereFormReassign] = useState(null);
    const [reassignDocument, setReassignDocument] = useState(false);
    const { context } = useParams();

    const pourcentage = Math.round((Number(indexFile + 1) / Number(maxIndexFile + 1)) * 100);

    const openNewWindow = () => {
        window.open(
            `${window.location.origin}/ma-tache/${context}/${task.id}/mes-documents`,
            'all-documents',
            'toolbar=0,location=0,menubar=0,width=1470,height=670,resizable,scrollbars=yes,status=1,top=100',
        );
    };

    return (
        <>
            <div className={'col-12'}>
                <HeaderContainer>
                    <div className="progress">
                        <div
                            className="progress-bar bg-info"
                            role="progressbar"
                            style={{ width: pourcentage + '%' }}
                            aria-valuenow={indexFile}
                            aria-valuemin="0"
                            aria-valuemax={maxIndexFile}
                        />
                    </div>
                    <div className={'col-8'}>
                        <div className={'text-center f-16'}>
                            <div className={'row'}>
                                <div className={'col-6  mt-1'}>
                                    {files_url.length > 0 && (
                                        <h2 className={'title-primary'}>
                                            <i className="bi bi-file-earmark-pdf" /> Document n°{' '}
                                            <span className={'f-12'}>
                                                <strong className={' font-white'}>{indexFile + 1}</strong> /{' '}
                                                {maxIndexFile + 1}
                                            </span>
                                            <span
                                                onClick={() => openNewWindow()}
                                                className="text-white f-12 mx-3 cursor-pointer hover-underline"
                                            >
                                                Voir tous les documents
                                            </span>
                                        </h2>
                                    )}
                                    <p className={'f-12 text-white'}>
                                        {task.contract.souscripteur.titre} {task.contract.souscripteur.nom} - N° de
                                        téléphone du client : {task.contract.souscripteur.phone} <br />
                                        Apporteur : {task.contract.apporteurs.apporteur1.raisonSociale}{' '}
                                        {task.contract.apporteur1 !== '43397' &&
                                            '- N° du courtier : ' + task.contract.apporteurs.apporteur1.phone}
                                    </p>
                                </div>
                                {!taskId && (
                                    <div className={'col-6 mt-2'}>
                                        <div className={'d-inline-block h-100'}>
                                            <span className={'title-primary pt-2 d-block'}>
                                                Traitement impossible :{' '}
                                            </span>
                                        </div>
                                        <button
                                            onClick={() => {
                                                setShowFormReassign(reassignDocument ? true : !showFormReassign);
                                                setReassignDocument(false);
                                                setPaniereFormReassign('TACHE_ATT');
                                            }}
                                            type={'button'}
                                            className={'btn btn-outline-secondary float-end btn-reassign'}
                                        >
                                            Réaffecter en attente
                                        </button>
                                        <button
                                            onClick={() => {
                                                setShowFormReassign(reassignDocument ? true : !showFormReassign);
                                                setReassignDocument(false);
                                                setPaniereFormReassign(formContext !== 'RES' ? 'AN_NIV2' : 'RES_NIV2');
                                            }}
                                            type={'button'}
                                            className={'btn btn-outline-secondary float-end btn-reassign me-2'}
                                        >
                                            Réaffecter au niveau 2
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </HeaderContainer>
            </div>

            <div className={'container-fluid wrap-content'}>
                {/*<APILink href={c.link_xls} download rel="noopener noreferrer" className={'h3 float-right'} >*/}

                <div className={'row'}>
                    {files_url.length > 0 ? (
                        <>
                            {files_url[indexFile] && (
                                <Formik
                                    initialValues={initialValues}
                                    onSubmit={(values) =>
                                        postValidateDocument({
                                            ...values,
                                            is_automation_treatment:
                                                files_url[indexFile]?.automation_treatment_status === 'received',
                                            origin: origin,
                                            document_type_origin: files_url[indexFile]?.code,
                                        })
                                    }
                                    enableReinitialize
                                >
                                    {({
                                        handleSubmit,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        values,
                                        setFieldValue,
                                    }) => (
                                        <form onSubmit={handleSubmit} className={'row'}>
                                            <div className={'row my-5'}>
                                                <div className="col-7">
                                                    <div className={'row sticky-top pt-4'}>
                                                        <TypeDocumentContainer
                                                            codeProduit={codeProduit}
                                                            formContext={formContext}
                                                            values={values}
                                                            errors={errors}
                                                            setFieldValue={setFieldValue}
                                                            file={files_url[indexFile]}
                                                        />
                                                        {formContext !== 'RES' && (
                                                            <div className={'col-3'}>
                                                                <button
                                                                    className={'btn btn-outline-secondary float-end'}
                                                                    type="button"
                                                                    onClick={() => setShowAddDoc(true)}
                                                                >
                                                                    <i className="bi bi-file-earmark-plus me-2 f-18 align-middle" />{' '}
                                                                    Ajouter un document
                                                                </button>
                                                            </div>
                                                        )}
                                                        {formContext === 'RES' && (
                                                            <div className={'pt-4 col-12'}>
                                                                <p
                                                                    className={'bg-dark-2 p-4 text-white'}
                                                                    dangerouslySetInnerHTML={{ __html: task.title }}
                                                                />
                                                            </div>
                                                        )}
                                                        <ImgDocument file={files_url[indexFile]} />
                                                    </div>
                                                </div>

                                                <div className="col pt-4">
                                                    {showAddDoc && (
                                                        <AddDocumentContainer
                                                            setShowAddDoc={setShowAddDoc}
                                                            file={files_url[indexFile]}
                                                            formContext={formContext}
                                                        />
                                                    )}
                                                    <div>
                                                        <FieldsDocumentContainer
                                                            codeProduit={codeProduit}
                                                            formContext={formContext}
                                                            taskId={taskId}
                                                            values={values}
                                                            errors={errors}
                                                            file={files_url[indexFile]}
                                                            popup_id={popup_id}
                                                            chrono_id={task.ordre}
                                                            contract_id={contract_id}
                                                            maxIndexFile={maxIndexFile}
                                                            terminateUrl={terminateUrl}
                                                            setFieldValue={setFieldValue}
                                                            handleChange={handleChange}
                                                            handleBlur={handleBlur}
                                                            showFormReassign={showFormReassign}
                                                            setShowFormReassign={setShowFormReassign}
                                                            reassignDocument={reassignDocument}
                                                            setReassignDocument={setReassignDocument}
                                                            paniereFormReassign={paniereFormReassign}
                                                            setPaniereFormReassign={setPaniereFormReassign}
                                                        />
                                                    </div>

                                                    {showFormReassign && (
                                                        <ReassignTaskContainer
                                                            paniereFormReassign={paniereFormReassign}
                                                            chrono_id={task.ordre}
                                                            file_id={files_url[indexFile]?.id}
                                                            contract_id={contract_id}
                                                            indexFile={indexFile}
                                                            maxIndexFile={maxIndexFile}
                                                            reassignDocument={reassignDocument}
                                                            terminateUrl={terminateUrl}
                                                            handleChange={handleChange}
                                                            handleBlur={handleBlur}
                                                            setFieldValue={setFieldValue}
                                                            formContext={formContext}
                                                            values={values}
                                                            {...props}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </form>
                                    )}
                                </Formik>
                            )}
                        </>
                    ) : (
                        <div className={'text-center mt-5'}>
                            {loading ? (
                                <LargeSpinner />
                            ) : (
                                <div>
                                    <h1>⚠️ Aucun document pour cette tache ⚠️</h1>
                                </div>
                            )}
                        </div>
                    )}
                </div>

                {files_url[indexFile]?.automation_treatment_received_at && (
                    <>
                        <ul className="list-unstyled d-flex gap-4">
                            <li>
                                <b>Traitée le :</b> {files_url[indexFile]?.automation_treatment_received_at}
                            </li>
                            <li>
                                <b>Status :</b> {files_url[indexFile]?.automation_treatment_status?.toUpperCase()}
                            </li>
                        </ul>

                        <div className="mb-5 d-flex align-items-center gap-3">
                            <APILink
                                href={`${APP_URL_PASS}/payload/input/document/${files_url[indexFile]?.id}/type`}
                                download
                                rel="noopener noreferrer"
                                className={'btn btn-outline-secondary d-inline-block px-3 py-2'}
                                fileName={`input_type_${files_url[indexFile]?.id}.json`}
                            >
                                Export input type
                            </APILink>

                            <APILink
                                href={`${APP_URL_PASS}/payload/input/document/${files_url[indexFile]?.id}/fields`}
                                download
                                rel="noopener noreferrer"
                                className={'btn btn-outline-secondary d-inline-block px-3 py-2'}
                                fileName={`input_fields_${files_url[indexFile]?.id}.json`}
                            >
                                Export input fields
                            </APILink>
                            <APILink
                                href={`${APP_URL_PASS}/payload/output/document/${files_url[indexFile]?.id}/type`}
                                // download rel="noopener noreferrer"
                                className={'btn btn-outline-secondary d-inline-block px-3 py-2'}
                                fileName={`output_type_${files_url[indexFile]?.id}.json`}
                            >
                                Open output type
                            </APILink>
                            <APILink
                                href={`${APP_URL_PASS}/payload/output/document/${files_url[indexFile]?.id}/fields`}
                                // download rel="noopener noreferrer"
                                className={'btn btn-outline-secondary d-inline-block px-3 py-2'}
                                fileName={`output_fields_${files_url[indexFile]?.id}.json`}
                            >
                                Open output fields
                            </APILink>
                            <button
                                className={'btn btn-outline-secondary d-inline-block px-3 py-2'}
                                onClick={() => postRetryDocument(files_url[indexFile]?.id)}
                                disabled={loadingRetryDocument}
                            >
                                {loadingRetryDocument ? (
                                    <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                ) : (
                                    'Réanalyser le document'
                                )}
                            </button>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default WorkSpace;
