import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import Checkbox from '../../../../../../Commun/Inputs/Checkbox/Checkbox';
import Radio from '../../../../../../Commun/Inputs/Radio/Radio';
import { renderInputByType } from '../renderInputByType';

const FieldDocument = (props) => {
    const {
        f,
        values,
        setFieldValue,
        handleChange,
        handleBlur,
        file: { fields },
        showHiddenFields,
    } = props;
    const [showField, setShowField] = useState(f?.field_dependencies.length === 0 ?? false);

    useEffect(() => {
        if (f.field_dependencies.length !== 0) {
            /// Un champ peut s'afficher en fonction de l'état ou de la nouvelle valeur de son/ses input(s) parent(s)
            /// Pour l'affichage en fonction du state alors on doit tous les avoir pour affiché le champ
            /// Pour l'affichage en fonction de la new value un seul par type suffit
            const state_dependencies = _.filter(f.field_dependencies, { display_by: 'state' });
            const new_value_dependencies = _.filter(f.field_dependencies, { display_by: 'new_value' });
            let newShowFieldState = true;
            let newShowFieldValue = true;

            if (!_.isEmpty(state_dependencies)) {
                newShowFieldState = !_.find(state_dependencies, function (dependency) {
                    return (
                        values.data?.[`${dependency.document_parent_field.input_name}`]?.state !==
                        dependency.display_value
                    );
                });
            }
            if (!_.isEmpty(new_value_dependencies)) {
                var groupeDependences = _.uniqBy(new_value_dependencies, 'document_field_parent_id');

                // On vient chercher si pour un des groupe de dépendance on a pas une des valeurs attendues.
                newShowFieldValue = !_.find(groupeDependences, function (groupe) {
                    return !_.find(new_value_dependencies, function (dependency) {
                        return (
                            dependency.document_field_parent_id === groupe.document_field_parent_id &&
                            values.data?.[`${dependency.document_parent_field.input_name}`]?.new_value ===
                                dependency.display_value
                        );
                    });
                });
            }
            const newShowField = newShowFieldState && newShowFieldValue;

            if (!newShowField) {
                if (values.data?.[f.input_name]?.['new_value'] !== null) {
                    setFieldValue(`data[${f.input_name}]['new_value']`, null);
                }
                if (values.data?.[f.input_name]?.['state'] !== 1) {
                    setFieldValue(`data[${f.input_name}]['state']`, 1);
                }
            }

            setShowField(newShowField);
        }

        // reset du champs modifier si on repasse l'état à OK
        if (values.data?.[f.input_name]?.['state'] === '1' && values.data?.[f.input_name]?.['new_value']) {
            setFieldValue(`data[${f.input_name}]['new_value']`, null);
        }

        if (f.is_hidden_field) {
            if (!showHiddenFields) {
                setShowField(false);
            }
            setFieldValue(`data[${f.input_name}]['automation_treatment']`, 1);
        } else {
            setFieldValue(`data[${f.input_name}]['automation_treatment']`, 0);
        }
    }, [values.data, showHiddenFields]);

    // Fonction pour calculer la valeur CRM en fonction de la différence d'années
    const getCRMValue = (differenceYears) => {
        if (differenceYears < 1) {
            return '1.00';
        } else if (differenceYears >= 1 && differenceYears < 2) {
            return '0.95';
        } else if (differenceYears >= 2 && differenceYears < 3) {
            return '0.90';
        } else if (differenceYears >= 3 && differenceYears < 4) {
            return '0.85';
        } else if (differenceYears >= 4 && differenceYears < 5) {
            return '0.80';
        } else if (differenceYears >= 5 && differenceYears < 6) {
            return '0.76';
        } else if (differenceYears >= 6 && differenceYears < 7) {
            return '0.72';
        } else if (differenceYears >= 7 && differenceYears < 8) {
            return '0.68';
        } else if (differenceYears >= 8 && differenceYears < 9) {
            return '0.64';
        } else if (differenceYears >= 9 && differenceYears < 10) {
            return '0.60';
        } else if (differenceYears >= 10 && differenceYears < 11) {
            return '0.57';
        } else if (differenceYears >= 11 && differenceYears < 12) {
            return '0.54';
        } else if (differenceYears >= 12 && differenceYears < 13) {
            return '0.51';
        } else if (differenceYears >= 13) {
            return '0.5';
        }
    };

    const calculateCrmValue = (dateKey, formTypeKey, crmKey, currentDateKey) => {
        const providedDate = moment(values.data?.[dateKey]?.new_value, 'DD/MM/YYYY', true);
        const situationDate = moment(values.data?.[currentDateKey]?.new_value, 'DD/MM/YYYY', true);

        const subscriptionDateRaw = _.find(fields, ['input_name', 'PIECEOII-B_EFFET'])?.default_value;
        const subscriptionDate = subscriptionDateRaw
            ? moment(subscriptionDateRaw.split(' - ')[0].trim(), 'DD/MM/YYYY', true)
            : null;

        const differenceYears = situationDate.diff(providedDate, 'years');
        const subscriptionDifferenceYears = subscriptionDate ? subscriptionDate.diff(situationDate, 'years') : null;

        if (
            moment.isMoment(providedDate) &&
            providedDate.isValid() &&
            f.form_type === formTypeKey &&
            values.data?.['EXTERNE-DONNEES_ASSURE']?.new_value === 'conducteur'
        ) {
            if (subscriptionDifferenceYears > 2) {
                setFieldValue(`data[${crmKey}]['new_value']`, '1.00');
            } else {
                const crmValue = getCRMValue(differenceYears);
                setFieldValue(`data[${crmKey}]['new_value']`, crmValue);
            }
        }
    };

    useEffect(() => {
        const currentDateKey = f.form_type === 'D' ? 'EXTERNE-DATE_SITUATION_AU' : 'EXTERNE-DATE_SITUATION_MO';

        calculateCrmValue('EXTERNE-DATE_SOUS_AU_CONDUCTEUR', 'D', 'XCCRMOII-B_CRMAUTO_CONDUCTEUR', currentDateKey);
        calculateCrmValue('EXTERNE-DATE_SOUS_MO_CONDUCTEUR', 'C', 'XCCRMOII-B_CRMMOTO_CONDUCTEUR', currentDateKey);
    }, [
        values.data?.['EXTERNE-DATE_SOUS_AU_CONDUCTEUR']?.new_value,
        values.data?.['EXTERNE-DATE_SOUS_MO_CONDUCTEUR']?.new_value,
        values.data?.['EXTERNE-DATE_SITUATION_AU']?.new_value,
        values.data?.['EXTERNE-DATE_SITUATION_MO']?.new_value,
        values.data?.['PIECEOII-B_EFFET']?.default_value,
    ]);

    return showField ? (
        <div className={'mb-3'}>
            <div className="row">
                <div className={'d-flex flex-row align-items-flex-start'}>
                    <div
                        className={`input-width ${
                            values.data?.[`${f.input_name}`]?.state === '0' && f.minor_changes && 'has_minor'
                        }`}
                    >
                        {renderInputByType({
                            props,
                            file: f,
                            disabled: f.automatic_validation === false || f.editable === false,
                            showLabel: true,
                        })}
                        {values.data?.[`${f.input_name}`]?.state === '0' && f.minor_changes && (
                            <Checkbox
                                name={`data[${f.input_name}][has_minor_changes]`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.data?.[`${f.input_name}`]?.has_minor_changes}
                            />
                        )}
                    </div>
                    <div className={'radio-width ms-2'}>
                        {f.automatic_validation === false && (
                            <div className={`d-flex text-center`}>
                                <div className="">
                                    <Radio
                                        name={`data[${f.input_name}][state]`}
                                        value={'1'}
                                        type={'success'}
                                        required
                                        disabled={values.data?.[`${f.input_name}`]?.state === '0'}
                                        //isCheckedAutomatic={(f.input_name === 'IBANOII-B_BIC' || f.input_name === 'IBANOII-B_DOMICIL') && values.data?.[`${f.input_name}`]?.new_value !== null && values.data?.[`${f.input_name}`]?.state === 1}
                                    />
                                </div>
                                <div className="">
                                    <Radio
                                        name={`data[${f.input_name}][state]`}
                                        value={'0'}
                                        type={'danger'}
                                        required
                                        disabled={values.data?.[`${f.input_name}`]?.state === '1'}
                                        //isCheckedAutomatic={(f.input_name === 'IBANOII-B_BIC' || f.input_name === 'IBANOII-B_DOMICIL') && values.data?.[`${f.input_name}`]?.state !== 1}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {values.data?.[`${f.input_name}`]?.state === '0' &&
                (f.editable || values.data?.[`${f.input_name}`]?.has_minor_changes) && (
                    <div className="row">
                        <div className={'mb-2 input-width-change'}>
                            {renderInputByType({
                                props,
                                file: f,
                                showLabel: true,
                                correction: true,
                                fields,
                            })}
                        </div>
                    </div>
                )}
            <div>
                {(!f.editable &&
                    f.is_automation_validation &&
                    f.default_value &&
                    f.default_value_automation &&
                    f.default_value_automation !== f.default_value) ||
                    (!f.editable && f.default_value_automation && !f.is_automation_validation && (
                        <div>
                            <div
                                className={'alert alert-info mt-1 mb-0'}
                                role="alert"
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <p>
                                    Valeur reconnue par Johanna Zarca :{' '}
                                    <b>
                                        {f.default_value_automation ??
                                            (f.is_automation_validation !== null ? 'Non reconnu' : 'Pas sûr')}
                                    </b>
                                </p>

                                {!values.data?.[`${f.input_name}`]?.has_minor_changes &&
                                    f.minor_changes &&
                                    f.default_value_automation !== null && (
                                        <button
                                            type={'button'}
                                            className={'btn p-0 btn-link'}
                                            onClick={() => {
                                                setFieldValue(
                                                    `data[${f.input_name}]['new_value']`,
                                                    f.default_value_automation,
                                                );
                                                setFieldValue(`data[${f.input_name}]['has_minor_changes']`, true);
                                            }}
                                        >
                                            Remplacer
                                        </button>
                                    )}
                            </div>
                        </div>
                    ))}
            </div>
            {f.comment && (
                <div
                    className="alert alert-warning mt-3"
                    role="alert"
                    dangerouslySetInnerHTML={{ __html: f.comment }}
                />
            )}
        </div>
    ) : (
        <></>
    );
};

export default FieldDocument;
