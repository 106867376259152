import { dashboardMiddleware } from './dashboard/dashboard.middleware';
import { linksMiddleware } from './links/links.middleware';
import { loginMiddleware } from './login/login.middleware';
import { settingsMiddleware } from './settings/settings.middleware';
import { tasksMiddleware } from './tasks/tasks.middleware';
import { userMiddleware } from './user/user.middleware';
import { controlledDocsMiddleware } from './controlledDocs/controlledDocs.middleware';

export const appMiddleware = [
    linksMiddleware,
    loginMiddleware,
    tasksMiddleware,
    userMiddleware,
    dashboardMiddleware,
    settingsMiddleware,
    controlledDocsMiddleware
];
