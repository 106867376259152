// import { POST_VALIDATE_FIELDS } from '../tasks/tasks.actions';

export const CONTROLLED_DOCS = '[ControlledDocs]'

export const GET_CONTROLLED_DOCS = `${CONTROLLED_DOCS} Get`
export const SET_CONTROLLED_DOCS = `${CONTROLLED_DOCS} Set`

export const VALIDATED_CONTROLLED_DOCUMENT = `${CONTROLLED_DOCS} Validated`

export const getControlledDocs = ({query}) => ({
    type: GET_CONTROLLED_DOCS,
    payload: {
        data: query
    }
})

export const setControlledDocs = ({data}) => ({
    type: SET_CONTROLLED_DOCS,
    payload: {
        data
    }
})

export const validatedControlledDocument = ({query, form, otherData}) => ({
    type: VALIDATED_CONTROLLED_DOCUMENT,
    payload: {
        data: query,
        body: form,
        otherData
    }
})
