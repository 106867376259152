import _ from 'lodash';
import { useEffect } from 'react';
import LargeSpinner from '../../../../../Commun/Spinners/LargeSpinner/LargeSpinner';
import FieldDocument from '../../WorkSpace/FieldsDocument/FieldDocument/FieldDocument';
import { ListDocuments } from '../../WorkSpace/TypeDocument/ListDocuments';
import FieldDocumentSimple from './FieldDocumentSimple';

const FieldsDocument = (props) => {
    let {
        file: { file_id, fields = [], contract_id, doc_type },
        values,
        loading,
        indexFile,
        setFieldValue,
    } = props;

    useEffect(() => {
        setFieldValue('data', {});
        setFieldValue('file_id', file_id);
        setFieldValue('contract_id', contract_id);
        setFieldValue('start_timer', Math.floor(Date.now() / 1000));
        setFieldValue('doc_type', fields[0]?.doc_type);
    }, [props.file]);

    useEffect(() => {
        fields.forEach((f) => {
            if (f.editable === true) {
                setFieldValue(
                    `data[${f.input_name}][new_value]`,
                    values.data?.[f.input_name]?.new_value || f.default_value_automation,
                );
            }

            if (f.is_automation_validation === true && f.automatic_validation === false) {
                setFieldValue(`data[${f.input_name}][state]`, '1');
            } else if (f.is_automation_validation === false && f.automatic_validation === false) {
                setFieldValue(`data[${f.input_name}][state]`, '0');
            }
        });
    }, [fields]);

    const libelleDoc = _.find(ListDocuments('AN'), ['code', doc_type]).libelle;

    return !values.data ? (
        <LargeSpinner />
    ) : (
        <div>
            <FieldDocumentSimple
                label={`Le type du document "${libelleDoc}" est-il correct ?`}
                input_name="type_validity"
                values={values}
                setFieldValue={setFieldValue}
            />

            <hr />

            {values.type_validity === '1' &&
                _.map(fields, (f, i) => (
                    <FieldDocument key={i} f={f} values={values} {...props} showHiddenFields={true} />
                ))}

            <hr />

            <div className="d-flex flex-row-reverse mt-5 justify-content-between align-items-start">
                <button
                    className={`btn btn-primary btn-primary-green`}
                    type={loading ? 'button' : 'submit'}
                    style={{ minWidth: 150 }}
                    disabled={loading}
                >
                    {loading ? (
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                    ) : (
                        <span>
                            Valider et passer au document suivant <span className={'d-inline-block ms-2'}>➜</span>
                        </span>
                    )}
                </button>
            </div>
        </div>
    );
};

export default FieldsDocument;
