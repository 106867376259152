import {createSelector} from 'reselect'

const userSelector = state => state.user

export const getNameUser = createSelector(
    userSelector,
    user => user.name
)

export const getUserPermissions = createSelector(
    userSelector,
    user => user.permissions
)
export const getUserRoles = createSelector(
    userSelector,
    user => user.roles
)
