import { SET_CONTROLLED_DOCS } from '../../actions/app/controlledDocs/controlledDocs.actions';

export const controlledDocsReducer = (state = {}, action = {}) => {
    const { payload } = action;

    if (action.type === SET_CONTROLLED_DOCS) {
        return payload.data;
    } else {
        return state;
    }
};
