import _ from 'lodash';
import { useEffect, useState } from 'react';
import LargeSpinner from '../../../../../Commun/Spinners/LargeSpinner/LargeSpinner';
import FieldDocument from './FieldDocument/FieldDocument';
import ReassignDocumentButton from './ReassignDocumentButton/ReassignDocumentButton';

const FieldsDocument = (props) => {
    let {
        popup_id,
        file: { id, fields = [], code, fiche_pratique },
        values,
        loadingTypage,
        loading,
        indexFile,
        setFieldValue,
        showFormReassign,
        setShowFormReassign,
        reassignDocument,
        setReassignDocument,
        maxIndexFile,
        formContext,
        paniereFormReassign,
        setPaniereFormReassign,
        taskId,
    } = props;

    const [showHiddenFields, setShowHiddenFields] = useState(false);
    const countHiddenFields = fields.filter((f) => f.is_hidden_field).length;
    const hasHiddenFields = countHiddenFields > 0;

    useEffect(() => {
        setFieldValue('data', {});
        setFieldValue('file_id', id);
        setFieldValue('form_type_origin', code);
        setFieldValue('start_timer', Math.floor(Date.now() / 1000));
        setFieldValue('doc_type', fields[0]?.doc_type);
    }, [indexFile, props.file]);

    // Utilisé pour le dernier document
    useEffect(() => {
        if (!values.form_type_origin) {
            setFieldValue('form_type_origin', code);
        }
        if (!values.popup_id) {
            setFieldValue('popup_id', popup_id);
        }
        if (!values.file_id) {
            setFieldValue('file_id', id);
        }
        if (!values.start_timer) {
            setFieldValue('start_timer', Math.floor(Date.now() / 1000));
        }
        if (!values.doc_type) {
            setFieldValue('doc_type', fields[0]?.doc_type);
        }
    });

    useEffect(() => {
        fields.forEach((f) => {
            if (f.editable === true) {
                setFieldValue(
                    `data[${f.input_name}][new_value]`,
                    values.data?.[f.input_name]?.new_value || f.default_value_automation,
                );
            }

            if (f.is_automation_validation === true && f.automatic_validation === false) {
                setFieldValue(`data[${f.input_name}][state]`, '1');
            } else if (f.is_automation_validation === false && f.automatic_validation === false) {
                setFieldValue(`data[${f.input_name}][state]`, '0');
            }
            // if (f.automatic_validation === true ) {
            //     setFieldValue(`data[${f.input_name}][state]`, '1');
            // }
        });
    }, [fields]);

    const TextButton =
        maxIndexFile === indexFile
            ? `Valider et clôturer la tâche <i class="bi bi-check-lg ms-2" />`
            : `Valider la pièce <span className={'d-inline-block ms-2'}>➜</span>`;
    const ClassButton = maxIndexFile === indexFile ? 'btn-primary btn-primary-green' : 'btn-primary';

    console.table(
        fields.map((f) => {
            return {
                key: `${f.belair_field}-${f.belair_table}`,
                type: f.type,
                editable: f.editable,
                automatic_validation: f.automatic_validation,
                is_automation_validation: f.is_automation_validation,
                minor_changes: f.has_minor_changes,
                is_hidden_field: f.is_hidden_field,
            };
        }),
    );

    return (
        <>
            {loadingTypage ? (
                <LargeSpinner />
            ) : (
                <div>
                    {fiche_pratique?.file && (
                        <div className={'d-flex flex-row-reverse pb-5'}>
                            <a
                                href={fiche_pratique?.file}
                                download
                                className="d-inline-block btn-text-hover"
                                target={'_blank'}
                                rel={'noreferrer'}
                            >
                                <div className="wrap">
                                    <span className={'text'}>Voir la fiche pratique</span>
                                    <span className={'icon'}>
                                        <i className="bi bi-question ps-1"></i>
                                    </span>
                                </div>
                            </a>
                        </div>
                    )}

                    {countHiddenFields > 0 && (
                        <div className="alert alert-info" role="alert">
                            {countHiddenFields === fields.length
                                ? "Nous n'arrivons pas réussi à passer ce document en automatique. Merci de vérifier ces champs."
                                : `Une partie des champs de ce document a été validée en automatique.`}
                        </div>
                    )}

                    {_.map(fields, (f, i) => (
                        <FieldDocument key={i} f={f} values={values} showHiddenFields={showHiddenFields || countHiddenFields === fields.length} {...props} />
                    ))}

                    {/*Show hidden fields*/}
                    {/*{hasHiddenFields && (*/}
                    {/*    <div className="d-flex mt-5 justify-content-between align-items-center">*/}
                    {/*        <span>{countHiddenFields} champs validées en automatique</span>*/}

                    {/*        <span*/}
                    {/*            className="cursor-pointer text-decoration-underline"*/}
                    {/*            onClick={() => setShowHiddenFields(!showHiddenFields)}*/}
                    {/*        >*/}
                    {/*            {showHiddenFields ? 'Cacher les champs masqués' : 'Afficher les champs masqués'}*/}
                    {/*        </span>*/}
                    {/*    </div>*/}
                    {/*)}*/}

                    <hr />
                    {Object.keys(fields).length > 0 ? (
                        <div className="d-flex flex-row-reverse mt-5 justify-content-between align-items-start">
                            <button
                                className={`btn ${ClassButton}`}
                                type={loading ? 'button' : 'submit'}
                                style={{ minWidth: 150 }}
                            >
                                {loading ? (
                                    <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                ) : (
                                    <p dangerouslySetInnerHTML={{ __html: TextButton }} />
                                )}
                            </button>
                            {!taskId && (
                                <ReassignDocumentButton
                                    formContext={formContext}
                                    paniereFormReassign={paniereFormReassign}
                                    setPaniereFormReassign={setPaniereFormReassign}
                                    setShowFormReassign={setShowFormReassign}
                                    reassignDocument={reassignDocument}
                                    showFormReassign={showFormReassign}
                                    setReassignDocument={setReassignDocument}
                                    lastDocument={maxIndexFile === indexFile}
                                />
                            )}
                        </div>
                    ) : (
                        <div className={'alert alert-danger'}>
                            Le typage automatique du document n’existe pas. Merci de retyper manuellement le document
                            avec la liste en haut à gauche
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default FieldsDocument;
