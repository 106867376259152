import Radio from '../../../../../Commun/Inputs/Radio/Radio';

const FieldDocument = (props) => {
    const { values, setFieldValue, input_name, label } = props;

    const handleChange = (event) => {
        setFieldValue(input_name, event.target.value);
    };

    const value = values[input_name];

    return (
        <div className="mb-3">
            <div className="row">
                <div className="d-flex flex-row align-items-flex-start">
                    <div className="input-width">
                        {/*<label className="form-label">{label || input_name}</label>*/}
                        <div
                            className={`form-group no-input ${
                                value === '1' ? 'success-input' : value === '0' ? 'error-input' : 'no-input'
                            }`}
                        >
                            <label>{label}</label>
                        </div>
                    </div>
                    <div className="radio-width ms-2">
                        <div className="d-flex text-center">
                            <div className="">
                                <Radio
                                    name={input_name}
                                    value={'1'}
                                    type={'success'}
                                    required
                                    onChange={handleChange}
                                    disabled={value === '0'}
                                />
                            </div>
                            <div className="">
                                <Radio
                                    name={input_name}
                                    value={'0'}
                                    type={'danger'}
                                    required
                                    onChange={handleChange}
                                    disabled={value === '1'}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FieldDocument;
