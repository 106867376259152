import React from "react";
import _ from 'lodash'
import Text from "../../../../../Commun/Inputs/Text/Text";
import Select from "../../../../../Commun/Inputs/Select/Select";
import Iban from "../../../../../Commun/Iban/Iban";

export const renderInputByType = ({props, file, disabled, showLabel, correction, fields}) => {
    const { setFieldValue, values, errors, handleChange, handleBlur } = props

    let name; let value; let error;

    if (!disabled){
        name = `data[${file.input_name}][new_value]`
        value = values.data?.[file.input_name]?.new_value
        error = errors.data?.[file.input_name]?.new_value
    } else {
        name = file.input_name
        value = values[file.input_name] || file.default_value
        error = errors[file.input_name]
    }

    if(file.automatic_validation === true && file.editable  === false){
        setFieldValue(`data[${file.input_name}][state]`, '1');
    }

    switch (file?.type) {
        case 'date':
        case 'text':
        case 'number':
        case 'year':
            return (
                <Text
                    isValid={(!correction) && values.data?.[`${file.input_name}`]?.state}
                    type={_.includes(['text', 'number'], file.type) ? 'text' : file.type}
                    name={name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={_.isString(value) ? value?.toUpperCase() : value || ""}
                    required
                    placeholder={file.label}
                    label={showLabel ? correction ? 'Saisir la valeur présente sur le document' : file.label : null}
                    maxLength={file.max_length}
                    disabled={disabled}
                    additional_label={file.additional_label}
                    values={values}
                    file={file}
                />
            )
        case 'select':
            return (
                <Select
                    name={name}
                    error={error}
                    value={value || ""}
                    label={showLabel ? file.label : null}
                    disabled={disabled}
                    additional_label={file.additional_label}
                    required
                >
                    <option value={""}>{file.label}</option>
                    {
                        _.map(file.field_select_values, option => (
                            <option value={option.key} key={option.key}>{option.value}</option>
                        ))
                    }
                </Select>
            )
        case 'indicativeFieldsGroup':
            return (
                <div>
                    <label className={'mb-2'}><b>{file.additional_label}</b> {file.label}</label>
                    <div className={`form-group no-input pb-2`} style={{height: 'auto'}}>
                        <div className="row">
                            {
                                _.map(file.default_value, v => (
                                    <div className="col-6 mb-2">
                                        <p>{v.label}</p>
                                        <p className={'text-white'}>{v.value}</p>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            )
        case 'iban':
            return (
                <Iban
                    label={showLabel ? correction ? 'Saisir la valeur présente sur le document' : file.label : null}
                    correction={correction}
                    file={file}
                    handleBlur={handleBlur}
                    values={values}
                    name={name}
                    handleChange={handleChange}
                    value={value}
                    disabled={disabled}
                    setFieldValue={setFieldValue}
                    nameBic={_.find(fields, ["label", "Code BIC"])?.input_name}
                    nameDomiciliation={_.find(fields, ["label", "Domiciliation"])?.input_name}
                    />
            )
        case 'info':
            return (
                <div className="alert alert-warning m-0" role="alert">
                    {file.label} : {file.default_value} mois
                </div>
            )
        default:
            return (
                <div className={`form-group no-input ${values.data?.[`${file.input_name}`]?.state === '1' ? 'success-input' : values.data?.[`${file.input_name}`]?.state === '0' ? 'error-input' : 'no-input'}`}>
                    <label><b>{file.additional_label}</b> {file.label}</label>
                </div>
            )
    }

}
