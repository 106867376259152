import { connect } from 'react-redux';
import {
    postReassignTaskDocument,
    POST_TYPAGE_DOCUMENT,
    POST_VALIDATE_FIELDS,
} from '../../../../../../redux/actions/app/tasks/tasks.actions';
import { getIndexFileUi, getLoadingEntity } from '../../../../../../redux/selectors/ui/ui.selectors';
import FieldsDocument from './FieldsDocument';

const mapStateToProps = (state) => {
    return {
        loadingTypage: getLoadingEntity(state, POST_TYPAGE_DOCUMENT),
        loading: getLoadingEntity(state, POST_VALIDATE_FIELDS),
        indexFile: getIndexFileUi(state),
    };
};

const mapDispatchToProps = {
    postReassignTaskDocument,
};

const FieldsDocumentContainer = connect(mapStateToProps, mapDispatchToProps)(FieldsDocument);

export default FieldsDocumentContainer;
