import {
    GET_CONTROLLED_DOCS, getControlledDocs,
    setControlledDocs,
    VALIDATED_CONTROLLED_DOCUMENT
} from '../../../actions/app/controlledDocs/controlledDocs.actions';
import { setError, setIndexFile, setLoader, setMaxIndexFile } from '../../../actions/app/ui/ui.actions';
import { apiRequest, API_ERROR, API_SUCCESS } from '../../../actions/core/api/api.actions';
import { setNotification } from '../../../actions/core/notifications/notifications.actions';
import moment from 'moment';
import _ from 'lodash';

export const controlledDocsMiddleware =
    ({ dispatch }) =>
    (next) =>
    (action) => {
        next(action);

        let nextActions;

        switch (action.type) {
            /* FETCH_FIRST_TASKS */
            case GET_CONTROLLED_DOCS:
                next([
                    apiRequest({
                        body: null,
                        method: 'GET',
                        url: action.payload.data,
                        entity: GET_CONTROLLED_DOCS,
                        otherData: { ...action.payload.otherData },
                    }),
                    setLoader({ state: true, entity: GET_CONTROLLED_DOCS }),
                ]);
                break;

            case `${GET_CONTROLLED_DOCS} ${API_SUCCESS}`:
                // nextActions = [];
                // if (action.payload.data.message) {
                // nextActions.push(
                //     setNotification({
                //         data: action.payload.data.message,
                //         html: action.payload.data.message,
                //         icon: 'success',
                //         entity: GET_CONTROLLED_DOCS,
                //         title: 'Tâche traitée !',
                //         confirmButtonText: "J'ai compris",
                //     }),
                // );
                // } else {
                // nextActions = [
                //     setIndexFile({ data: 0, entity: FETCH_FIRST_TASKS }),
                //     setMaxIndexFile({
                //         data: action.payload.data.files_url.length - 1,
                //         entity: FETCH_FIRST_TASKS,
                //     }),
                //     setFirstTasks({ data: action.payload.data }),
                // ];
                // }

                next([
                    // ...nextActions,
                    setControlledDocs({ data: action.payload.data }),
                    setIndexFile({ data: 0, entity: GET_CONTROLLED_DOCS }),
                    setMaxIndexFile({
                        data: action.payload.data.length - 1,
                        entity: GET_CONTROLLED_DOCS,
                    }),
                    setError({ state: false, entity: GET_CONTROLLED_DOCS }),
                    setLoader({ state: false, entity: GET_CONTROLLED_DOCS }),
                ]);
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
                break;

            case `${GET_CONTROLLED_DOCS} ${API_ERROR}`:
                next([
                    // setNotification({
                    //     entity: FETCH_FIRST_TASKS,
                    //     html: `<div><p>${
                    //         action.payload.data.response?.data.reason ||
                    //         (action.payload.meta?.otherData?.taskId
                    //             ? "La tâche n'est plus disponible"
                    //             : "Il n'y a plus de tâches a traiter")
                    //     }</p></div>`,
                    //     icon: 'error',
                    //     title: 'Attention !',
                    //     confirmButtonText: "J'ai compris",
                    // }),
                    setError({ state: true, entity: GET_CONTROLLED_DOCS }),
                    setLoader({ state: false, entity: GET_CONTROLLED_DOCS }),
                ]);
                break;

            case VALIDATED_CONTROLLED_DOCUMENT:
                console.log(action.payload)
                let form = {};

                Object.keys(action.payload.body.data).forEach((field) => {
                    if (_.isDate(action.payload.body.data[field].new_value)) {
                        form[`data[${field}][new_value]`] = moment(action.payload.body.data[field].new_value).format(
                            'L',
                        );
                    } else {
                        form[`data[${field}][new_value]`] = action.payload.body.data[field].new_value || '';
                    }

                    form[`data[${field}][has_minor_changes]`] = action.payload.body.data[field].has_minor_changes
                        ? '1'
                        : '0';
                    form[`data[${field}][state]`] = action.payload.body.data[field].state || '1';
                    form[`data[${field}][automation_treatment]`] =
                        action.payload.body.data[field].automation_treatment || '0';
                });

                /* FIX IBAN BIC */
                if (!_.isEmpty(action.payload.body.iban)) {
                    Object.keys(action.payload.body.iban).forEach((field) => {
                        form[`data[${field}][new_value]`] = action.payload.body.iban[field].new_value || '';
                        form[`data[${field}][state]`] = action.payload.body.iban[field].state || '1';
                    });
                }

                form.contract_id = action.payload.body.contract_id;
                form.doc_type = action.payload.body.doc_type;
                form.file_id = action.payload.body.file_id;
                form.start_timer = action.payload.body.start_timer;
                form.end_timer = Math.floor(Date.now() / 1000);
                form.type_validity = action.payload.body.type_validity;

                next([
                    apiRequest({
                        body: form,
                        method: 'POST',
                        url: action.payload.data,
                        entity: VALIDATED_CONTROLLED_DOCUMENT,
                        otherData: {
                            ...action.payload.otherData,
                        },
                    }),
                    setLoader({ state: true, entity: VALIDATED_CONTROLLED_DOCUMENT }),
                ]);
                break;

            case `${VALIDATED_CONTROLLED_DOCUMENT} ${API_SUCCESS}`:
                const { queryControlledDocs } = action.payload.meta.otherData;
                next([
                    setControlledDocs({ data: [] }),
                    setError({ state: false, entity: VALIDATED_CONTROLLED_DOCUMENT }),
                    setLoader({ state: false, entity: VALIDATED_CONTROLLED_DOCUMENT }),
                ]);
                dispatch(getControlledDocs({ query: queryControlledDocs }));

                // if (indexFile === maxIndexFile) {
                //     next(nextActions);
                //     dispatch(postCloseTasks({ query: terminateUrl, otherData: action.payload.meta.otherData }));
                // } else {
                //     nextActions.push(setIndexFile({ data: indexFile + 1, entity: VALIDATED_CONTROLLED_DOCUMENT }));
                //     next(nextActions);
                //     window.scrollTo({
                //         top: 0,
                //         behavior: 'smooth',
                //     });
                // }

                break;

            case `${VALIDATED_CONTROLLED_DOCUMENT} ${API_ERROR}`:
                next([
                    setNotification({
                        entity: VALIDATED_CONTROLLED_DOCUMENT,
                        html: `<div><p>${action.payload.data.response.data.reason}</p></div>`,
                        icon: 'error',
                        title: 'Attention !',
                        confirmButtonText: "J'ai compris",
                    }),
                    setError({ state: true, entity: VALIDATED_CONTROLLED_DOCUMENT }),
                    setLoader({ state: false, entity: VALIDATED_CONTROLLED_DOCUMENT }),
                ]);
                break;

            default:
                break;
        }
        return null;
    };
