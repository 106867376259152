import { Formik } from 'formik';
import { APP_URL_PASS } from '../../../../../config/constants';
import APILink from '../../../../Commun/APILink/APILink';
import LargeSpinner from '../../../../Commun/Spinners/LargeSpinner/LargeSpinner';
import HeaderContainer from '../../Header/HeaderContainer';
import ImgDocument from '../WorkSpace/ImgDocument/ImgDocument';
import FieldsDocumentContainer from './FieldsDocument/FieldsDocumentContainer';

const WorkSpaceControl = (props) => {
    const { docs, loading, indexFile, maxIndexFile, validatedControlledDocument } = props;
    return (
        <>
            <div className={'col-12'}>
                <HeaderContainer></HeaderContainer>
            </div>

            <div className={'container-fluid wrap-content'}>
                <div className={'row'}>
                    {docs.length > 0 ? (
                        <>
                            {docs[indexFile] && (
                                <Formik
                                    initialValues={{
                                        type_validity: '1'
                                    }}
                                    onSubmit={(values) =>
                                        validatedControlledDocument({
                                            ...values,
                                            end_timer: Math.floor(Date.now() / 1000),
                                        })
                                    }
                                    enableReinitialize
                                >
                                    {({
                                        handleSubmit,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        values = {},
                                        setFieldValue,
                                    }) => (
                                        <form onSubmit={handleSubmit} className={'row'}>
                                            <div className={'row my-5'}>
                                                <div className="col-7">
                                                    <div className={'row sticky-top pt-4'}>
                                                        <ImgDocument file={docs[indexFile]} />
                                                    </div>
                                                </div>

                                                <div className="col pt-4">
                                                    <FieldsDocumentContainer
                                                        formContext={'AN'}
                                                        values={values}
                                                        errors={errors}
                                                        file={docs[indexFile]}
                                                        maxIndexFile={maxIndexFile}
                                                        setFieldValue={setFieldValue}
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                    />
                                                </div>
                                            </div>
                                        </form>
                                    )}
                                </Formik>
                            )}
                        </>
                    ) : (
                        <div className={'text-center mt-5'}>
                            {loading ? (
                                <LargeSpinner />
                            ) : (
                                <div>
                                    <h1>⚠️ Aucun document pour cette tache ⚠️</h1>
                                </div>
                            )}
                        </div>
                    )}
                </div>

                <div className="mb-5 d-flex align-items-center gap-3">
                    <APILink
                        href={`${APP_URL_PASS}/payload/input/document/${docs[indexFile]?.file_id}/type`}
                        download
                        rel="noopener noreferrer"
                        className={'btn btn-outline-secondary d-inline-block px-3 py-2'}
                        fileName={`input_type_${docs[indexFile]?.file_id}.json`}
                    >
                        Export input type
                    </APILink>

                    <APILink
                        href={`${APP_URL_PASS}/payload/input/document/${docs[indexFile]?.file_id}/fields`}
                        download
                        rel="noopener noreferrer"
                        className={'btn btn-outline-secondary d-inline-block px-3 py-2'}
                        fileName={`input_fields_${docs[indexFile]?.file_id}.json`}
                    >
                        Export input fields
                    </APILink>
                    <APILink
                        href={`${APP_URL_PASS}/payload/output/document/${docs[indexFile]?.file_id}/type`}
                        // download rel="noopener noreferrer"
                        className={'btn btn-outline-secondary d-inline-block px-3 py-2'}
                        fileName={`output_type_${docs[indexFile]?.file_id}.json`}
                    >
                        Open output type
                    </APILink>
                    <APILink
                        href={`${APP_URL_PASS}/payload/output/document/${docs[indexFile]?.file_id}/fields`}
                        // download rel="noopener noreferrer"
                        className={'btn btn-outline-secondary d-inline-block px-3 py-2'}
                        fileName={`output_fields_${docs[indexFile]?.file_id}.json`}
                    >
                        Open output fields
                    </APILink>
                </div>
            </div>
        </>
    );
};

export default WorkSpaceControl;
