import { Link } from 'react-router-dom';
import LogoCheckDoc from '../../../../assets/logo/checkdoc-white.png';

const Header = (props) => {
    const { name, children } = props;

    return (
        <>
            <div className={'row bg-dark p-2 header'}>
                <div className="container-fluid">
                    <div className={'row d-flex justify-content-between'}>
                        <div className="col-2">
                            <Link to={'/'}>
                                <img src={LogoCheckDoc} alt={'Check Doc'} width={130} />
                            </Link>
                        </div>
                        {children}
                        {window.location.pathname === '/' && (
                            <div className="col-2 pe-4 pt-2 text-end align-middle user-log">
                                <div className={'user-content'}>
                                    <p className={'text-white m-0 fw-bold f-12'}>{name}</p>
                                    <Link
                                        to="/logout"
                                        className="m-0 d-inline-block cursor-pointer text-decoration-none fw-bolder outer-title f-10"
                                    >
                                        Se déconnecter
                                    </Link>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Header;
