import { useQuery } from '@tanstack/react-query';
import { requestServices } from '../utils/request';

export const useIban = (iban) =>
    useQuery({
        queryKey: ['iban', iban],
        queryFn: () => requestServices.get(`/iban/${iban}`).then((res) => res.data),
        enabled: !!iban && iban.length >= 24 && iban.length <= 32,
        retry: 0,
    });
