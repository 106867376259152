import { connect } from 'react-redux';
import { compose } from 'redux';
import {
    getControlledDocs,
    GET_CONTROLLED_DOCS,
    validatedControlledDocument,
} from '../../../../../redux/actions/app/controlledDocs/controlledDocs.actions';
import { setIndexFile } from '../../../../../redux/actions/app/ui/ui.actions';
import { getControlledDocsData } from '../../../../../redux/selectors/controlledDocs/controlledDocs.selector';
import {
    getControlledDocumentsLink,
    getControlledDocumentsValidatedLink,
} from '../../../../../redux/selectors/links/links.selectors';
import { getIndexFileUi, getLoadingEntity, getMaxIndexFileUi } from '../../../../../redux/selectors/ui/ui.selectors';
import loader from '../../../../Commun/Loader/loader';
import WorkSpaceControl from './WorkSpaceControl';

const mapStateToProps = (state) => {
    return {
        loading: getLoadingEntity(state, GET_CONTROLLED_DOCS),
        loaded: getLoadingEntity(state, GET_CONTROLLED_DOCS) === false,
        query: getControlledDocumentsLink(state),
        queryValidated: getControlledDocumentsValidatedLink(state),
        docs: getControlledDocsData(state),
        indexFile: getIndexFileUi(state),
        maxIndexFile: getMaxIndexFileUi(state),
    };
};

const mapDispatchToProps = {
    getControlledDocs,
    validatedControlledDocument,
    setIndexFile,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { query, queryValidated } = stateProps;
    const { getControlledDocs, validatedControlledDocument } = dispatchProps;

    return {
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        validatedControlledDocument: (form) =>
            validatedControlledDocument({
                query: queryValidated,
                form,
                otherData: { queryControlledDocs: query },
            }),
        load: () => {
            getControlledDocs({ query });
        },
    };
};

const WorkSpaceControlContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader(),
)(WorkSpaceControl);

export default WorkSpaceControlContainer;
