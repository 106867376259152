import * as variables from "../constants/constants";
import axios from "axios";

const defaultOptions = {
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
};

export const requestServices = axios.create({
    baseURL: variables.SERVICES_URL,
    ...defaultOptions,
});