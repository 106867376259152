import React, { memo, useEffect } from 'react';
import { useField } from 'formik';
import {useIban} from "../../../api/services";
import SmallSpinner from "../Spinners/SmallSpinner";
import Text from "../Inputs/Text/Text";

const IbanField = memo(({ nameIban = 'ibanField', setFieldValue, values, name, nameBic, nameDomiciliation }) => {
    const [field, meta] = useField(nameIban);
    const queryIBAN = useIban(values?.[nameIban]);

    const errorMessage = queryIBAN.error?.response?.data?.message;

    useEffect(() => {
        if (queryIBAN.isSuccess && queryIBAN.data?.bic) {
            setFieldValue('bic', queryIBAN.data.bic);
            setFieldValue('domiciliation', queryIBAN.data.bank);

            setFieldValue(name, queryIBAN.data.iban);
            setFieldValue(nameBic, queryIBAN.data.bic);
            setFieldValue(nameDomiciliation, queryIBAN.data.bank);
            setFieldValue(`iban[${nameDomiciliation}][new_value]`, queryIBAN.data.bank);
            setFieldValue(`iban[${nameBic}][new_value]`, queryIBAN.data.bic);
            setFieldValue(`iban[${nameBic}][state]`, 1);
            setFieldValue(`iban[${nameDomiciliation}][state]`, 1);

        } else if (queryIBAN.isError && !queryIBAN.isFetching) {
            setFieldValue('bic', '');
            setFieldValue('domiciliation', '');

            setFieldValue(`iban[${nameBic}][state]`, 0);
            setFieldValue(`iban[${nameDomiciliation}][state]`, 0);
        }
    }, [queryIBAN.isSuccess, queryIBAN.isError]);

    return (
        <div className="row">
            <div className="col-12">
                <Text
                    label={'IBAN'}
                    name={nameIban}
                    value={field.value}
                    onChange={(e) => {
                        field.onChange(e);
                        setFieldValue('bic', '');
                        setFieldValue('domiciliation', '');
                    }}
                    onBlur={field.onBlur}
                    error={meta.touched && (meta.error || (queryIBAN.isError && !queryIBAN.isFetching && errorMessage))}
                />
                {queryIBAN.isLoading && <SmallSpinner />}
            </div>
            <div className="col-12">
                <Text
                    label={'Domiciliation de la banque'}
                    name="domiciliation"
                    value={values.domiciliation || ''}
                    disabled
                    normalize={v => v.toUpperCase()}
                />
            </div>
            <div className="col-12">
                <Text
                    label={'Code BIC'}
                    name="bic"
                    value={values.bic || ''}
                    disabled
                    normalize={v => v.toUpperCase()}
                />
            </div>

            {
                errorMessage && <div className="col-12">
                    <div className={'alert-danger alert mb-0'} dangerouslySetInnerHTML={{__html: errorMessage}}/>
                </div>
            }
        </div>
    );
}, (prevProps, nextProps) => {
    return prevProps.values === nextProps.values;
});

export default IbanField;
