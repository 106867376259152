import { combineReducers } from 'redux';
import { controlledDocsReducer } from './controlledDocs/controlledDocs.reducer';
import { dashboardReducer } from './dashboard/dashboard.reducer';
import { linksReducer } from './links/links.reducer';
import { settingsReducer } from './settings/settings.reducer';
import { tasksReducer } from './tasks/tasks.reducer';
import { uiReducer } from './ui/ui.reducer';
import { userReducer } from './user/user.reducer';

export const AppReducers = combineReducers({
    links: linksReducer,
    ui: uiReducer,
    task: tasksReducer,
    user: userReducer,
    dashboard: dashboardReducer,
    settings: settingsReducer,
    controlledDocs: controlledDocsReducer,
});
